import PropTypes from 'prop-types';
import React from 'react';

import { useMobileMediaQuery } from '@/hooks/useMobileMediaQuery';

import { Wrapper } from './OnlyMobile.style';

/**
 * Present content only in mobile (below MUI 'md' breakpoint).
 */
const OnlyMobile = ({ children, unmountOnHide }) => {
  const mobileMediaQuery = useMobileMediaQuery();

  if (unmountOnHide) {
    return mobileMediaQuery ? children : null;
  }

  return <Wrapper>{children}</Wrapper>;
};

OnlyMobile.propTypes = {
  /** Content to be presented. */
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  unmountOnHide: PropTypes.bool,
};

export default OnlyMobile;
