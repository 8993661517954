import PropTypes from 'prop-types';
import React from 'react';

import OnlyDesktop from '@/components/ui/OnlyDesktop/OnlyDesktop';
import OnlyMobile from '@/components/ui/OnlyMobile/OnlyMobile';
import PageMargins from '@/components/ui/PageMargins/PageMargins';

import HowItWorksDesktop from './HowItWorksDesktop/HowItWorksDesktop';
import HowItWorksMobile from './HowItWorksMobile/HowItWorksMobile';

const HowItWorks = ({ data }) => {
  return (
    <PageMargins>
      <OnlyDesktop unmountOnHide>
        <HowItWorksDesktop data={data} />
      </OnlyDesktop>
      <OnlyMobile unmountOnHide>
        <HowItWorksMobile data={data} />
      </OnlyMobile>
    </PageMargins>
  );
};

HowItWorks.propTypes = {
  data: PropTypes.shape({
    tag: PropTypes.string,
    headline: PropTypes.string.isRequired,
    subline: PropTypes.string.isRequired,
    description: PropTypes.string,
    link: PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        headline: PropTypes.string.isRequired,
        text: PropTypes.string,
        link: PropTypes.shape({
          text: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        }),
        slides: PropTypes.arrayOf(
          PropTypes.shape({
            image: PropTypes.shape({
              url: PropTypes.string.isRequired,
              alt: PropTypes.string,
            }).isRequired,
          }).isRequired
        ).isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
};

export default HowItWorks;
