import { Box, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';

import {
  Carousel,
  Dot,
  Inner,
  InnerWrapper,
  PlaceholderDot,
  Slide,
} from './Slider.style';

const Slider = ({ slides, fullWidth }) => {
  const multipleSlides = slides.length > 1;
  const [activeIndex, setActiveIndex] = useState(multipleSlides ? 1 : 0);
  const [transitioning, setTransitioning] = useState(false);
  const innerRef = useRef(null);
  const itemsInfinite = multipleSlides
    ? [slides[slides.length - 1], ...slides, slides[0], slides[1]]
    : slides;

  const dotActive = dotIndex => {
    return (
      (activeIndex === 0 && dotIndex === slides.length - 1) ||
      (activeIndex === itemsInfinite.length - 2 && dotIndex === 0) ||
      activeIndex === dotIndex + 1
    );
  };

  const updateIndex = newIndex => {
    if (!multipleSlides || transitioning || newIndex == activeIndex) {
      return;
    } else if (innerRef.current) {
      innerRef.current.style.transition = 'transform 0.5s ease-in-out';
      setTransitioning(true);
      setActiveIndex(newIndex);
    }
  };

  const handleTransitionEnd = () => {
    if (!multipleSlides) {
      return;
    } else if (innerRef.current) {
      setTransitioning(false);
      if (activeIndex === 0) {
        innerRef.current.style.transition = 'none';
        setActiveIndex(itemsInfinite.length - 3);
      } else if (activeIndex === itemsInfinite.length - 2) {
        innerRef.current.style.transition = 'none';
        setActiveIndex(1);
      }
    }
  };

  const handleMove = direction => {
    if (transitioning || !multipleSlides) {
      return;
    } else if (direction === 'prev') {
      updateIndex(activeIndex - 1);
    } else {
      updateIndex(activeIndex + 1);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handleMove(),
    onSwipedRight: () => handleMove('prev'),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const renderSlide = ({ image: { url }, index }) => {
    return (
      <Box
        key={index}
        width={fullWidth ? '100%' : '85%'}
        pr={fullWidth ? 0 : 2}
        flexShrink={0}>
        <Slide url={url} />
      </Box>
    );
  };

  return (
    <Carousel {...handlers}>
      <InnerWrapper fullWidth={fullWidth}>
        <Inner
          ref={innerRef}
          onTransitionEnd={handleTransitionEnd}
          style={{
            transform: `translate(-${activeIndex * (fullWidth ? 100 : 85)}%)`,
          }}>
          {itemsInfinite.map((item, index) => renderSlide({ ...item, index }))}
        </Inner>
      </InnerWrapper>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={0.5}
        mt={2.5}>
        {multipleSlides ? (
          [...new Array(slides.length)].map((_, index) => (
            <Dot active={dotActive(index)} key={index} />
          ))
        ) : (
          <PlaceholderDot />
        )}
      </Stack>
    </Carousel>
  );
};

Slider.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string,
      }).isRequired,
    }).isRequired
  ).isRequired,
  //* fullWidth - only one slide visible at a time *//
  fullWidth: PropTypes.bool,
};

Slider.defaultProps = {
  fullWidth: false,
};

export default Slider;
